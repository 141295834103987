<template>
  <form class="login-sign-in" @submit.prevent="onSubmit" novalidate autocomplete="off">
    <div class="login-sign-in__title">
      <ui-title :title="$t('login.signIn.title')" align="center" />
    </div>
    <ui-input
      class="login-sign-in__input"
      v-model="email"
      type="email"
      id="email"
      :label="$t('login.signIn.email')"
      icon="mail_outline"
      :reverse="true"
      design="minimalist"
      :error="$v.email.$error"
    >
      <template v-if="$v.email.$error && !$v.email.required">
        {{ $t('errors.required') }}
      </template>
      <template v-else-if="$v.email.$error && !$v.email.email">
        {{ $t('errors.email') }}
      </template>
    </ui-input>
    <ui-input
      class="login-sign-in__input"
      v-model="password"
      id="password"
      type="password"
      :label="$t('login.signIn.password')"
      icon="lock"
      :reverse="true"
      design="minimalist"
      :error="$v.password.$error"
    >
      <template v-if="$v.password.$error && !$v.password.required">
        {{ $t('errors.required') }}
      </template>
    </ui-input>
    <div class="login-sign-in__remember">
      <ui-checkbox id="remember-me" v-model="rememberMe">
        {{ $t('login.signIn.rememberMe') }}
      </ui-checkbox>
    </div>
    <div class="login-sign-in__cta">
      <ui-button
        class="login-sign-in__submit"
        button="cta"
        type="submit"
        :label="$t('login.signIn.submit')"
        variant="gtr"
        :disabled="logging"
      />
    </div>
    <div class="login-sign-in__cta">
      <ui-link :path="{ name: 'ForgotPassword' }">
        {{ $t('login.signIn.forgotPassword') }}
      </ui-link>
    </div>
    <!-- <ui-separator class="login-sign-in__separator" :label="$t('login.signIn.separator')" />
    <div class="login-sign-in__cta">
      <ui-button
        class="login-sign-in__cta__google"
        button="primary"
        type="button"
        :label="$t('login.signIn.googleConnect')"
        svg="google"
      />
    </div> -->
  </form>
</template>

<script>
import UiTitle from '@/components/UI/Title.vue'
import UiInput from '@/components/UI/Input.vue'
import UiCheckbox from '@/components/UI/Checkbox.vue'
import UiLink from '@/components/UI/Link.vue'
import UiButton from '@/components/UI/Button.vue'
// import UiSeparator from '@/components/UI/Separator.vue'
import { notification } from '@/utils/notification.util'

import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  name: 'LoginSignIn',
  components: {
    UiTitle,
    UiInput,
    UiCheckbox,
    UiLink,
    UiButton,
    // UiSeparator,
  },
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
      logging: false,
    }
  },
  methods: {
    ...mapActions({
      userLogin: 'user/userLogin',
    }),
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.logging = true
        this.userLogin({ email: this.email, password: this.password, rememberMe: this.rememberMe })
          .then(() => this.$router.push(this.$route.query.redirect || { name: 'Home' }))
          .catch(() => {
            this.logging = false
            notification({
              text: this.$t('login.signIn.notification.error'),
              type: 'error',
            })
          })
      }
    },
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.login-sign-in {
  &__title {
    display: flex;
    justify-content: center;
    margin-bottom: $gutter-tablet;

    @media (min-width: $screen-xl) {
      margin-bottom: $gutter-desktop;
    }
  }

  &__input {
    margin-bottom: $gutter-tablet;

    @media (min-width: $screen-xl) {
      margin-bottom: $gutter-desktop;
    }
  }

  &__remember,
  &__cta {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $gutter-tablet;

    @media (min-width: $screen-xl) {
      margin-bottom: $gutter-desktop;
    }

    &__google {
      width: 100%;
    }
  }

  &__remember {
    justify-content: flex-start;
  }

  &__cta {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__separator {
    margin: $gutter-tablet 0;

    @media (min-width: $screen-xl) {
      margin: $gutter-desktop 0;
    }
  }

  &__submit {
    width: 100%;
  }
}
</style>
